import React from 'react';

// Style imports
import './CVDownload.scss';

// Asset imports
import CV from '../../assets/images/content/CV.png';

const CVDownload = () => {
    return (
        <section className='cv-download'>
            <div className='cv-download__container container'>
                <div className='cv-download__image-wrapper'>
                    <img className='cv-download__image' src={CV} alt='CV cover thumbnail' />
                </div>

                <div className='cv-download__content'>
                    <h2 className='cv-download__heading'>Download my latest CV</h2>
                    <p className='cv-download__description'>Find out more about my current skills, responsibilities, and experience in Frontend web development. An insight into my employment history and abilities.</p>
                    <a className='button button--hover-outline cv-download__download' href='/documents/James_Parker_CV.pdf' download='James_Parker_CV.pdf'>
                        Download CV
                        <span className='accessible'>
                            <abbr title="Adobe Portable Document Format">PDF</abbr>,
                            88<abbr title="Kilobyte">KB</abbr>
                        </span>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default CVDownload;