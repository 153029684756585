import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Component imports
import Circle from '../shared/Circle/Circle.jsx';

// Style imports
import './CaseStudies.scss';

// Asset imports
import ParkyGames from '../../assets/images/content/parkygames.jpg';
import MoneyBeans from '../../assets/images/content/moneybeans.png';
import JPDevelopment from '../../assets/images/content/jp-development.jpg';

const CaseStudies = () => {
    const handleAnimation = () => {
        gsap.registerPlugin(ScrollTrigger);
    
        gsap.to('#case-studies-circle-desktop', {
            scrollTrigger: {
                trigger: '#case-studies-circle-desktop',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });

        gsap.to('#case-studies-circle-mobile', {
            scrollTrigger: {
                trigger: '#case-studies-circle-mobile',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });
    };

    useEffect(() => {
        handleAnimation();
    }, []);

    return (
        <section className='case-studies'>
            <div className='case-studies__container container'>
                <div className='case-studies__inner'>
                    <h2 className='case-studies__heading'>Check out some of my personal projects</h2>
                    <p className='case-studies__description'>View recent examples of personal projects that I have undertaken – click a link to visit the site or download my case studies report for a more in-depth insight.</p>
                    <a className='case-studies__download' href='/documents/Case_Studies.pdf' download='Case_Studies.pdf'>
                        Download cases
                        <span className='accessible'>
                            <abbr title="Adobe Portable Document Format">PDF</abbr>,
                            688<abbr title="Kilobyte">KB</abbr>
                        </span>
                    </a>
                </div>

                <div className='case-studies__inner'>
                    <ul className='case-studies__list'>
                        <li className='case-studies__list-item'>
                            <a className='case-studies__link' href='http://jpdevelopment.co.uk/'>
                                <div className='case-studies__image-wrapper'>
                                    <img className='case-studies__image' src={JPDevelopment} alt='' aria-hidden='true' />
                                    <span className='case-studies__link-overlay'></span>
                                </div>
                                JP Web Development, Gatsby
                            </a>
                        </li>

                        <li className='case-studies__list-item'>
                            <a className='case-studies__link' href='https://parkygames.com/'>
                                <div className='case-studies__image-wrapper'>
                                    <img className='case-studies__image' src={ParkyGames} alt='' aria-hidden='true' />
                                    <span className='case-studies__link-overlay'></span>
                                </div>
                                ParkyGames YouTube Fansite, React
                            </a>
                        </li>

                        <li className='case-studies__list-item'>
                            <a className='case-studies__link' href='https://moneybeans.co.uk/'>
                                <div className='case-studies__image-wrapper'>
                                    <img className='case-studies__image' src={MoneyBeans} alt='' aria-hidden='true' />
                                    <span className='case-studies__link-overlay'></span>
                                </div>
                                moneyBeans Finance Tools, Gatsby
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Desktop Circles */}
            <Circle
                color='#7E8588'
                height={575}
                width={575}
                top={-230}
                right={-170}
                opacity={0.1}
                desktop
            />

            <Circle
                color='#7E8588'
                height={535}
                width={535}
                top={-230}
                right={-170}
                opacity={0.1}
                thickness={40}
                desktop
            />

            <Circle
                color='#7E8588'
                height={360}
                width={360}
                top={175}
                right={54}
                opacity={0.05}
                thickness={40}
                desktop
            />

            <Circle
                variant='gradient'
                height={576}
                width={576}
                bottom={-215}
                left={-195}
                desktop
                id='case-studies-circle-desktop'
            />

            {/* Mobile Circles */}
            <Circle
                color='#7E8588'
                height={282}
                width={282}
                top={-180}
                right={-140}
                opacity={0.1}
                thickness={15}
                mobile
            />

            <Circle
                color='#7E8588'
                height={252}
                width={252}
                top={-180}
                right={-140}
                opacity={0.1}
                thickness={30}
                mobile
            />

            <Circle
                color='#7E8588'
                height={235}
                width={235}
                top={40}
                right={-130}
                opacity={0.05}
                thickness={20}
                mobile
            />

            <Circle
                variant='gradient'
                height={311}
                width={311}
                bottom={-90}
                left={-177}
                mobile
                id='case-studies-circle-mobile'
            />
        </section>
    );
}

export default CaseStudies;