import React from 'react';

// Layout imports
import Layout from '../layouts/Default/Default.jsx';
import SEO from '../layouts/SEO/SEO.jsx';

// Component imports
import Introduction from '../components/shared/Introduction/Introduction.jsx';
import PageSelector from '../components/shared/PageSelector/PageSelector.jsx';
import Promotion from '../components/shared/Promotion/Promotion.jsx';
import CVDownload from '../components/CVDownload/CVDownload.jsx';
import CaseStudies from '../components/CaseStudies/CaseStudies.jsx';

// Asset imports
import Me from '../assets/images/content/me.svg';

const Home = () => (
  <Layout>
    <SEO title='Frontend Web Development' />

    <Introduction
      heading='Frontend'
      subheading='Web Development'
      description='A Senior Frontend Developer with years of experience developing exciting user-focused web applications and solutions for a range of clients worldwide. Proficient with a number of Frontend technologies, specialising in React development and accessibility implementation.'
      imageSource={Me}
    />
    
    <PageSelector
      id='mobile-nav-selector'
      triggerText='Web Development'
    />

    <Promotion
      heading='Let’s create some amazing frontend experiences'
      description='Helping you to create high quality, accessible, fully responsive websites. Applying an in-depth understanding of how people will use your application to ensure excellent user experience.'
      list={[
        {
          icon: '/images/icons/stopclock.svg',
          text: 'Agile',
        },
        {
          icon: '/images/icons/check.svg',
          text: 'Quality coding',
        },
        {
          icon: '/images/icons/process.svg',
          text: 'User driven',
        }
      ]}
    />

    <CVDownload />

    <CaseStudies />
  </Layout>
)

export default Home;